import React from "react";
import { BsCalendarEventFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { PiShoppingCartSimpleFill } from "react-icons/pi";

export default function NoActivityComponent(){
  const isECommerce = useSelector((state: RootState) => state.chat.isECommerce);
    
  return(
    <div className="flex items-center justify-center h-[80vh] bg-white">
    <div className="flex flex-col items-center text-center">
      {isECommerce ? (
        <PiShoppingCartSimpleFill className="text-gray-400" size={40}/>
      ) : (
        <BsCalendarEventFill className="text-gray-400" size={40} />
      )}
      <h3 className="text-sm font-semibold text-gray-500 mt-4">
        {isECommerce ? "No orders available." : "No log activities available."}
      </h3>
    </div>
  </div>
    );
}