import { CircleUser } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "../../../../@/components/ui/avatar";
import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import AIReplyHandleButton from "./AIReplyHandleButton";
import { useMedia } from "react-use";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

interface SelectedChatHeaderProps {
    onBackClick: () => void;
    onShowDetails: () => void;
}

export default function SelectedChatHeader({
    onBackClick,
    onShowDetails,
}: SelectedChatHeaderProps){
    const isMobile = useMedia("(max-width: 640px)");
    const avaterImage = "";

    const userMobileNumber = useSelector(
        (state: RootState) => state.selectedUser.userMobileNumber
      );

    return(
        <>
        <div className="flex items-center space-x-2 ml-3 cursor-pointer">
            {isMobile && (
              <MdArrowBackIos
                onClick={onBackClick}
                className="text-[1.6rem] font-medium leading-none mr-2"
              >
                Back
              </MdArrowBackIos>
            )}
            <Avatar onClick={onShowDetails}>
              {avaterImage ? (
                <AvatarImage src={avaterImage} alt="Image" />
              ) : (
                <AvatarFallback>
                  <CircleUser className="h-9 w-9" />
                </AvatarFallback>
              )}
            </Avatar>

            <div onClick={onShowDetails}>
              <p className="text-sm font-medium leading-none">
                {userMobileNumber}
              </p>
              {/* <p className="text-sm text-muted-foreground">m@example.com</p> */}
            </div>
          </div>
          <AIReplyHandleButton />
        </>
    );
}