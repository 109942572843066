import React from "react";
import NavBar from "../navigationBar/NavigationBar";
//import ProductTable from "./ProductTable";
import ProductExcel from "./ProductExcel";

export default function ProductSection() {
  return (
    <>
      <NavBar />
      <div className="h-[90vh] flex flex-row">
        <ProductExcel/>
      </div>
    </>
  );
}