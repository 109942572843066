import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import {
  fetchLogActivities,
  postActivityLog,
} from "../../../../../store/ActivityLogSlice";
import WhatsappIcon from "../../../../../const/images/logActivity/whatsapp.png";
import CallIcon from "../../../../../const/images/logActivity/call.png";
import EmailIcon from "../../../../../const/images/logActivity/email.png";
import CustomIcon from "../../../../../const/images/logActivity/note.png";
import { useToast } from "@/hooks/use-toast";
import LogActivityDialog from "./LogActivityDialog";
import ActivityDropdown from "./LogActivityDropDown";

const activityTypes = [
  { title: "Call", icon: CallIcon },
  { title: "Email", icon: EmailIcon },
  { title: "WhatsApp", icon: WhatsappIcon },
  { title: "", icon: CustomIcon, custom: true },
];

export default function LogActivity() {
  const dispatch: AppDispatch = useDispatch();
  const { toast } = useToast();
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    title: "",
    description: "",
    isCustomField: false,
  });

  const [errorState, setErrorState] = useState({
    titleError: "",
    descriptionError: "",
  });

  const { loading } = useSelector((state: RootState) => state.activityLog);
  const user = useSelector((state: RootState) => state.selectedUser.userId);

  const handleDialogOpen = useCallback((title: string, customField: boolean = false) => {
    setDialogState({
      title,
      isOpen: true,
      isCustomField: customField,
      description: "",
    });
    setErrorState({ titleError: "", descriptionError: "" });
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogState((prevState) => ({ ...prevState, isOpen: false }));
  }, []);

  const validateForm = useCallback((): boolean => {
    let isValid = true;

    if (!dialogState.title) {
      setErrorState((prev) => ({ ...prev, titleError: "Title is required." }));
      isValid = false;
    } else {
      setErrorState((prev) => ({ ...prev, titleError: "" }));
    }

    if (!dialogState.description) {
      setErrorState((prev) => ({ ...prev, descriptionError: "Description is required." }));
      isValid = false;
    } else {
      setErrorState((prev) => ({ ...prev, descriptionError: "" }));
    }

    return isValid;
  }, [dialogState.title, dialogState.description]);

  const handleSave = useCallback(() => {
    if (!validateForm() || !user) return;

    dispatch(
      postActivityLog({
        title: dialogState.title,
        description: dialogState.description,
        user,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchLogActivities(user));
        handleDialogClose();
        toast({
          title: "Successfully added!",
          description: "Your new log activity has been saved successfully.",
        });
      })
      .catch((err) => {
        console.error("Failed to log activity:", err);
        toast({
          title: "Oh! Something went wrong.",
          description: "There was a problem with your request.",
        });
      });
  }, [dialogState, user, dispatch, handleDialogClose, toast, validateForm]);

  return (
    <>
      <ActivityDropdown onItemClick={handleDialogOpen} activityTypes={activityTypes} />

      <LogActivityDialog
        isOpen={dialogState.isOpen}
        onClose={handleDialogClose}
        onSave={handleSave}
        title={dialogState.title}
        description={dialogState.description}
        setTitle={(value) => setDialogState((prevState) => ({ ...prevState, title: value }))}
        setDescription={(value) => setDialogState((prevState) => ({ ...prevState, description: value }))}
        isCustomField={dialogState.isCustomField}
        loading={loading}
        titleError={errorState.titleError}
        descriptionError={errorState.descriptionError}
      />
    </>
  );
}
