import React from "react";
import NavBar from "../navigationBar/NavigationBar";
import CustomerTable from "./CustomerDetails";

export default function CustomerSection() {
  return (
    <>
      <NavBar />
      <div className="h-[90vh] flex flex-row">
        <CustomerTable/>
      </div>
    </>
  );
}
