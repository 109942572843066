import React from "react";
import ProductSection from "../pageComponents/products/ProductSection";

export default function ProductPage(){
    return(
        <div className="mt-[10vh]">
        <ProductSection/>
        </div>
    );
}
