import React, { useState } from "react";
import { Card, CardContent, CardDescription, CardHeader } from "../../@/components/ui/card";
import CreateLeadDialog from './CreateLeadDialog';

const initialTemplates = [
  { 
    id: 1, 
    title: "Lead Template 1", 
    leadFields: [
      { name: "user name", required: true, type: "string" },
      { name: "email", required: true, type: "string" },
      { name: "company", required: false, type: "string" },
      { name: "website", required: false, type: "string" }
    ],
    status: [
      { statName: "New" },
      { statName: "Confirmed" },
      { statName: "Delivered" },
      { statName: "Declined" }
    ]
  },
  { 
    id: 2, 
    title: "Lead Template 2", 
    leadFields: [
      { name: "user name", required: true, type: "string" },
      { name: "email", required: true, type: "string" },
      { name: "company", required: false, type: "string" },
      { name: "website", required: false, type: "string" }
    ],
    status: [
      { statName: "New" },
      { statName: "Confirmed" },
      { statName: "Delivered" },
      { statName: "Declined" }
    ]
  },
  { 
    id: 2, 
    title: "Lead Template 3", 
    leadFields: [
      { name: "user name", required: true, type: "string" },
      { name: "email", required: true, type: "string" },
      { name: "company", required: false, type: "string" },
      { name: "website", required: false, type: "string" }
    ],
    status: [
      { statName: "New" },
      { statName: "Confirmed" },
      { statName: "Delivered" },
      { statName: "Declined" }
    ]
  },
  { 
    id: 2, 
    title: "Lead Template 4", 
    leadFields: [
      { name: "user name", required: true, type: "string" },
      { name: "email", required: true, type: "string" },
      { name: "company", required: false, type: "string" },
      { name: "website", required: false, type: "string" }
    ],
    status: [
      { statName: "New" },
      { statName: "Confirmed" },
      { statName: "Delivered" },
      { statName: "Declined" }
    ]
  },
];

export default function LeadDetails() {
  const [templates] = useState(initialTemplates);


  return (
    <div className="w-full p-8">
      <div className="flex justify-between items-center ">
        <h1 className="text-[1rem] font-semibold">Your Lead Templates</h1>
        <div className="mb-10">
        <CreateLeadDialog/>
        </div>
        
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
        {templates.map((template) => (
          <Card key={template.id}>
            <CardHeader className="pb-2">
              <CardDescription>{template.title}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="text-sm font-semibold">Lead Fields:</div>
              <ul className="list-disc pl-5">
                {template.leadFields.map((field, index) => (
                  <li key={index}>
                    <div className="text-sm">{field.name}</div>
                    <div className="text-xs">(Type: {field.type}, Required: {field.required ? 'Yes' : 'No'})</div> 
                  </li>
                ))}
              </ul>
              <div className="text-sm font-semibold mt-2">Status Options:</div>
              <ul className="list-disc pl-5">
                {template.status.map((stat, index) => (
                  <li key={index}><div className="text-sm">{stat.statName}</div></li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
}
