import { Search } from "lucide-react";
import { Button } from "../../../../../@/components/ui/button";
import React, { useState } from "react";
import { Input } from "../../../../../@/components/ui/input";
import { AppDispatch, RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { setSearchTerm } from "store/chatSlice";

export default function ChatSearchBar(){
    const dispatch: AppDispatch = useDispatch();
    const { searchTerm } = useSelector((state: RootState) => state.chat);
    const [showSearch, setShowSearch] = useState(false);
    const handleSearchClick = () => {
        setShowSearch((prevShowSearch) => !prevShowSearch);
      };

      const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchTerm(event.target.value));
      };
    return(
        <>
         {!showSearch && (
          <Button
            variant="ghost"
            className="p-2 hover:bg-gray-100 transition-all"
            onClick={handleSearchClick}
          >
            <Search className="h-5 w-5 mr-2" />
            <span>Search</span>
          </Button>
        )}
        {showSearch && (
          <form className="flex-1 sm:flex-initial mt-2">
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search by a mobile number..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="pl-8 sm:w-[14rem] md:w-[16rem] lg:w-[18rem] border border-gray-300 rounded-lg focus:border-gray-400 focus:ring-1 focus:ring-gray-400"
              />
            </div>
          </form>
        )}
        </>
    );
}