import React from "react";
import { HiChatAlt2 } from "react-icons/hi";

export default function EmptyChatListComponent() {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center bg-white border border-gray-200 rounded-lg p-6 shadow-md">
      <div className="mb-4 flex flex-col items-center">
        <HiChatAlt2 className="text-gray-300" size={50} />
        <h3 className="text-sm font-semibold text-gray-500 mt-4">
          No Chats Found
        </h3>
      </div>
    </div>
  );
}
