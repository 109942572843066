import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../../../@/components/ui/pagination";
import React from "react";

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
}
export default function TablePaginationComponent({
  currentPage,
  totalPages,
  handlePageChange,
}: TablePaginationProps) {
  return (
    <>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              href="#"
              onClick={(e) => {
                if (currentPage === 1) {
                  e.preventDefault();
                } else {
                  handlePageChange(currentPage - 1);
                }
              }}
              className={`${
                currentPage === 1 ? "pointer-events-none text-gray-400" : ""
              }`}
            />
          </PaginationItem>
          {Array.from({ length: totalPages }, (_, index) => (
            <PaginationItem key={index}>
              <PaginationLink
                href="#"
                onClick={() => handlePageChange(index + 1)}
                className={`${currentPage === index + 1 ? "text-bold" : ""}`}
              >
                {index + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
          <PaginationItem>
            <PaginationNext
              href="#"
              onClick={(e) => {
                if (currentPage === totalPages) {
                  e.preventDefault();
                } else {
                  handlePageChange(currentPage + 1);
                }
              }}
              className={`${
                currentPage === totalPages
                  ? "pointer-events-none text-gray-400"
                  : ""
              }`}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </>
  );
}
