import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../@/components/ui/card"
import FacebookLoginComponent from "pageComponents/facebookESLogin"

export default function TestPage() {
  return (
    <div className="h-screen flex justify-center items-center">
      <Card className="mx-auto max-w-sm rounded-md rounded-[0.5rem]">
        <CardHeader>
          <CardTitle className="text-2xl text-[#09090B]">Login</CardTitle>
          <CardDescription className="text-[#71717A]">
            Enter your email below to login to your account
          </CardDescription>
        </CardHeader>
        <CardContent>
          <FacebookLoginComponent />
        </CardContent>
      </Card>
    </div>
  )
}
