import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchOrdersApi, OrderItem } from "../services/API";

// Thunk for fetching orders
export const fetchOrders = createAsyncThunk<OrderItem[], string>(
  "orders/fetchOrders",
  async (userId) => {
    const response = await fetchOrdersApi(userId);
    return response;
  }
);

interface OrderState {
  loading: boolean;
  error: string | null;
  orders: OrderItem[];
}

const initialState: OrderState = {
  loading: false,
  error: null,
  orders: [],
};

const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch orders";
      });
  },
});

export default orderSlice.reducer;
