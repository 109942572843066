import React from "react";
import { useSelector } from "react-redux";
import OrderTable from "./OrderTable";
import SelectedOrderDetails from "./SelectedOrderDetails";
import { RootState } from "store/store";
import { useMedia } from "react-use";

export default function OrderLayout() {
  const selectedOrderId = useSelector(
    (state: RootState) => state.ordersList.selectedOrderId
  );

  const isMobile = useMedia('(max-width: 900px)');

  return (
     <div className="h-[90vh] flex w-full">
      {isMobile ? (
        <>
          {selectedOrderId ? (
            <div className="w-full p-1 bg-white">
              <SelectedOrderDetails />
            </div>
          ) : (
            <div className="w-full">
              <OrderTable />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            className={`transition-all duration-300 ease-in-out ${
              selectedOrderId ? "w-3/4" : "w-full"
            }`}
          >
            <OrderTable />
          </div>
          {selectedOrderId && (
            <div className="w-1/4 pt-[10vh] bg-white">
              <SelectedOrderDetails />
            </div>
          )}
        </>
      )}
    </div>
  );
}
