import React, { useEffect } from "react";
import { Card, CardContent } from "../../../../@/components/ui/card";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchLogActivities } from "store/ActivityLogSlice";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";
import ActivityLoadingComponent from "./activityLogComponents/ActivityLoadingComponent";
import ErrorComponent from "pageComponents/chatSection/errorComponent/ErrorComponent";
import NoActivityComponent from "./activityLogComponents/NoActivityComponent";

export default function ActivityCard() {
  const dispatch: AppDispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.selectedUser.userId);
  const {
    activities = [],
    loading,
    error,
  } = useSelector((state: RootState) => state.activityLog);

  useEffect(() => {
    if (userId) {
      dispatch(fetchLogActivities(userId));
    }
  }, [dispatch, userId]);

  return (
    <ScrollArea className="h-[80vh]">
      <Card className="h-[80vh] ml-2 mr-2 sm:col-span-2 border-0">
        {loading ? (
          <ActivityLoadingComponent />
        ) : error ? (
          <ErrorComponent />
        ) : activities.length > 0 ? (
          activities.map((activity) => (
            <CardContent className="p-1 space-y-4">
              <div
                key={activity._id}
                className="border rounded-md p-4 shadow-sm bg-white flex flex-col space-y-2"
              >
                <div className="flex justify-between items-center">
                  <h4 className="text-[0.9rem] font-semibold capitalize">
                    {activity.title}
                  </h4>
                </div>
                <p className="text-[0.8rem] text-gray-600">
                  {activity.description}
                </p>
                <p className="text-[0.7rem] text-gray-400 text-right">
                  {new Date(activity.timestamp).toLocaleString()}
                </p>
              </div>
            </CardContent>
          ))
        ) : (
          <NoActivityComponent/>
        )}
      </Card>
    </ScrollArea>
  );
}
