import { Card, CardHeader } from "../../../../@/components/ui/card";
import { ScrollArea } from "../../../../@/components/ui/scroll-area";
import { Skeleton } from "../../../../@/components/ui/skeleton";
import React from "react";

export default function AboutCardLoadingComponent(){
    return(
        <ScrollArea className="h-[80vh]">
        <Card className="h-[80vh] sm:col-span-2 m-2 p-1 bg-white border-0">
          {Array(3)
            .fill(0)
            .map((_, idx) => (
              <Card
                key={idx}
                className="mb-6 bg-gray-50 shadow-sm rounded-lg border border-gray-200"
              >
                <CardHeader className="pb-4 pt-6 px-6 border-b border-gray-300 bg-gray-100 rounded-t-lg">
                  <Skeleton className="h-4 w-[150px]" />
                  <Skeleton className="h-4 w-[100px] mt-2" />
                </CardHeader>
                <div className="px-6 py-4 bg-white rounded-b-lg">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-3/4 mt-2" />
                  <Skeleton className="h-4 w-1/2 mt-2" />
                </div>
              </Card>
            ))}
        </Card>
      </ScrollArea>
    );
}