import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchSelectedUserLeadTemplateData,
  LeadDataResponse,
  updateLeadDetails,
} from "../services/API";

interface LeadDataState {
  leadData: LeadDataResponse[];  
  status: "idle" | "loading" | "succeeded" | "failed";  
  error: string | null;  
  selectedUserId: string | null;  
  updateStatus: "idle" | "loading" | "succeeded" | "failed"; 
  updateError: string | null;
}

const initialState: LeadDataState = {
  leadData: [],
  status: "idle",
  error: null,
  selectedUserId: null,
  updateStatus: "idle",
  updateError: null,
};

interface FetchLeadDataArgs {
  userId: string;
}

interface UpdateLeadDataArgs {
  userId: string;
  updateData: {
    business_id: string;
    lead_id: string;
    status: string;
    data: Record<string, string>;
  };
}

export const fetchLeadData = createAsyncThunk(
  "leadData/fetchLeadData",
  async ({ userId }: FetchLeadDataArgs, { rejectWithValue }) => {
    try {
      const data = await fetchSelectedUserLeadTemplateData(userId);
      console.log("slice lead data", data);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

// Async thunk to update lead data
export const updateLeadData = createAsyncThunk(
  "leadData/updateLeadData",
  async ({ userId, updateData }: UpdateLeadDataArgs, { rejectWithValue }) => {
    try {
      await updateLeadDetails(userId, updateData);
      return { userId, updateData };
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Failed to update lead data");
    }
  }
);

const leadDataSlice = createSlice({
  name: "leadData",
  initialState,
  reducers: {
    resetLeadData(state) {
      state.leadData = [];
      state.status = "idle";
      state.error = null;
    },
    setSelectedUserId(state, action: PayloadAction<string>) {
      state.selectedUserId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(
        fetchLeadData.fulfilled,
        (state, action: PayloadAction<LeadDataResponse[]>) => {
          state.status = "succeeded";
          state.leadData = action.payload;
        }
      )
      .addCase(fetchLeadData.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateLeadData.pending, (state) => {
        state.updateStatus = "loading";
        state.updateError = null;
      })
      .addCase(updateLeadData.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
      })
      .addCase(updateLeadData.rejected, (state, action: PayloadAction<any>) => {
        state.updateStatus = "failed";
        state.updateError = action.payload;
      });
  },
});

export const { resetLeadData, setSelectedUserId } = leadDataSlice.actions;

export default leadDataSlice.reducer;
