import { TabsList, TabsTrigger } from "../../../../../@/components/ui/tabs";
import React from "react";
import StatusFilterDropdownComponent from "./StatusFilterDropDownComponent";
import { Button } from "../../../../../@/components/ui/button";
import { File } from "lucide-react";

interface OrderHeaderProps {
    orderTime: string;
  handleTimeChange: (time: string) => void;
  selectedStatus: string;
  handleCheckboxChange: (status: string, isChecked: boolean) => void;

}

export default function OrderHeaderComponent({
    orderTime,
  handleTimeChange,
  selectedStatus,
  handleCheckboxChange,
}: OrderHeaderProps){
    return(
        <>
        <TabsList>
                  {["all", "week", "month", "year"].map((tab) => (
                    <TabsTrigger
                      key={tab}
                      value={tab}
                      onClick={() => handleTimeChange(tab)}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </TabsTrigger>
                  ))}
                </TabsList>
                <div className="ml-auto flex items-center gap-2">
                  <StatusFilterDropdownComponent
                  selectedStatus={selectedStatus}
                  handleCheckboxChange={handleCheckboxChange}
                  />
                  <Button
                    size="sm"
                    variant="outline"
                    className="h-7 gap-1 text-sm"
                  >
                    <File className="h-3.5 w-3.5" />
                    <span className="sr-only sm:not-sr-only">Export</span>
                  </Button>
                </div>
        </>
    );
}