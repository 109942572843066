import { Label } from "../../@/components/ui/label";
import { Button } from "../../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../@/components/ui/dialog";
import React, { useState } from "react";
import { Input } from "../../@/components/ui/input";
import { PlusIcon } from "lucide-react";
import { ScrollArea } from "../../@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../@/components/ui/select";
import { Checkbox } from "../../@/components/ui/checkbox";

export default function CreateLeadDialog() {
  const [leadName, setLeadName] = useState("");
  const [statuses, setStatuses] = useState([{ statName: "" }]);
  const [leadFields, setLeadFields] = useState([
    { name: "", required: false, type: "text" },
  ]);

  const addStatus = () => {
    setStatuses([...statuses, { statName: "" }]);
  };

  const addLeadField = () => {
    setLeadFields([...leadFields, { name: "", required: false, type: "text" }]);
  };

  const handleSave = () => {
    if (leadName.trim() === "") {
      return;
    }
    // const newLeadTemplate = {
    //   id: Math.random(),
    //   title: leadName,
    //   leadFields,
    //   status: statuses,
    // };
  };

  return (
    <Dialog>
      <DialogTrigger>
        <Button>Create New Lead Template</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Lead Template</DialogTitle>
          <DialogDescription>
            Enter details for the new lead template.
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="h-72 w-[100%] rounded-md border p-2">
          <div className="ml-2 mr-2">
            <div className="space-y-4 ">
              <Label htmlFor="leadName">1. Lead Name</Label>
              <Input
                id="leadName"
                value={leadName}
                onChange={(e) => setLeadName(e.target.value)}
                placeholder="Enter lead name"
              />
            </div>

            <div className=" mt-4">
              <div className="flex justify-between items-center">
                <Label>2. Status List</Label>
                <Button onClick={addStatus} variant="ghost">
                  <PlusIcon className="w-5 h-5" />
                </Button>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {statuses.map((status, index) => (
                  <div key={index} className="flex flex-col">
                    <Label className="mb-1">Status {index + 1}</Label>
                    <Input
                      value={status.statName}
                      onChange={(e) => {
                        const updatedStatuses = [...statuses];
                        updatedStatuses[index].statName = e.target.value;
                        setStatuses(updatedStatuses);
                      }}
                      placeholder={`Enter status ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="space-y-4 mt-4">
              <div className="flex justify-between items-center">
                <Label>3. Lead Fields</Label>
                <Button onClick={addLeadField} variant="ghost">
                  <PlusIcon className="w-5 h-5" />
                </Button>
              </div>
              {leadFields.map((field, index) => (
                <div key={index} className="flex items-center space-x-4 mt-2">
                  <div>
                    <Label className="mb-1">Lead Field Name</Label>
                    <Input
                      value={field.name}
                      onChange={(e) => {
                        const updatedFields = [...leadFields];
                        updatedFields[index].name = e.target.value;
                        setLeadFields(updatedFields);
                      }}
                      placeholder="Field Name"
                    />
                  </div>

                  <div className="flex flex-col  space-x-4">
                    <Label className="">Required</Label>
                    <div className="flex flex-raw items-center space-x-2">
                      <Checkbox
                        id={`required-yes-${index}`}
                        checked={field.required === true}
                        onCheckedChange={(checked) => {
                          const updatedFields = [...leadFields];
                          updatedFields[index].required = checked
                            ? true
                            : false;
                          setLeadFields(updatedFields);
                        }}
                      />
                      <label
                        htmlFor={`required-yes-${index}`}
                        className="text-sm font-medium"
                      >
                        Yes
                      </label>

                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id={`required-no-${index}`}
                          checked={field.required === false}
                          onCheckedChange={(checked) => {
                            const updatedFields = [...leadFields];
                            updatedFields[index].required = checked
                              ? false
                              : true;
                            setLeadFields(updatedFields);
                          }}
                        />
                        <label
                          htmlFor={`required-no-${index}`}
                          className="text-sm font-medium"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/3">
                    {" "}
                    <Label className="mb-1">Type</Label>
                    <Select
                      value={field.type}
                      onValueChange={(value) => {
                        const updatedFields = [...leadFields];
                        updatedFields[index].type = value;
                        setLeadFields(updatedFields);
                      }}
                    >
                      <SelectTrigger className="w-full border border-gray-300 bg-white text-gray-700 shadow-sm rounded-md">
                        <SelectValue placeholder="Select Type" />
                      </SelectTrigger>
                      <SelectContent className="bg-white text-gray-700 shadow-lg border border-gray-300 rounded-md">
                        <SelectItem value="text">String</SelectItem>
                        <SelectItem value="number">Number</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ScrollArea>
        <DialogFooter>
          <Button variant="outline">Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
