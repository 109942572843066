import React from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../@/components/ui/tabs";
import AboutCard from "./aboutSection/AboutCard";
import ActivityCard from "./activityLogSection/ActivityCard";
import { useMedia } from "react-use";
import { MdArrowBackIos } from "react-icons/md";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import OrderCard from "./orderSection/OrderDetailsCard";

interface DetailsSectionProps {
  onBackClick: () => void;
}

export default function DetailsSection({ onBackClick }: DetailsSectionProps) {
  const isMobile = useMedia("(max-width: 640px)");
  const isECommerce = useSelector((state: RootState) => state.chat.isECommerce);

  return (
    <div className="w-full mt-[1vh]">
      <Tabs defaultValue="account">
        <TabsList className="flex bg-gray-200">
          {isMobile && (
            <MdArrowBackIos
              onClick={onBackClick}
              className="text-[1.6rem] font-medium leading-none item-center ml-3"
            >
              Back
            </MdArrowBackIos>
          )}
          <TabsTrigger
            value="account"
            className="flex-1 text-center bg-white text-black text-black data-[state=active]:bg-white data-[state=active]:text-black data-[state=inactive]:bg-gray-200 data-[state=inactive]:text-gray-500"
          >
            {isECommerce ? "Orders" : "About"}
          </TabsTrigger>
          <TabsTrigger
            value="password"
            className="flex-1 text-center bg-white text-black text-black data-[state=active]:bg-white data-[state=active]:text-black data-[state=inactive]:bg-gray-200 data-[state=inactive]:text-gray-500"
          >
            {isECommerce ? "About" : "Activity"}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="account">
        {isECommerce ? <OrderCard /> : <AboutCard />}
          
        </TabsContent>
        <TabsContent value="password">
          {isECommerce ? <AboutCard /> : <ActivityCard />}
        </TabsContent>
      </Tabs>
    </div>
  );
}
