import React, { useEffect } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store/store";
import { logout } from 'store/authSlice';

interface PrivateRouteProps {
  element: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isTokenValid = (token: string | null): boolean => {
    if (!token) return false;
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000;
      return Date.now() < expirationTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return false;
    }
  };

  useEffect(() => {
    if (token && !isTokenValid(token)) {
      dispatch(logout());
      navigate('/', { replace: true });
    }
  }, [token, dispatch, navigate]);

  if (!token) return <Navigate to="/" replace />;
  
  return isTokenValid(token) ? element : <Navigate to="/" replace />;
};

export default PrivateRoute;