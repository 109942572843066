import { Button } from "../../../../../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../@/components/ui/dialog";
import { Input } from "../../../../../@/components/ui/input";
import { Label } from "../../../../../@/components/ui/label";
import React from "react";

interface ActivityDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (title: string, description: string) => void;
  title: string;
  description: string;
  setTitle: (value: string) => void;
  setDescription: (value: string) => void;
  isCustomField: boolean;
  loading: boolean;
  titleError?: string;
  descriptionError?: string;
}

export default function LogActivityDialog({
  isOpen,
  onClose,
  onSave,
  title,
  description,
  setTitle,
  setDescription,
  isCustomField,
  loading,
  titleError,
  descriptionError,
}: ActivityDialogProps) {
  const handleSaveClick = () => {
    onSave(title, description);
  };
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="rounded-lg">
        <DialogHeader className="text-left">
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            {isCustomField
              ? "Enter a custom title and description for the activity."
              : "Please fill in the details for the activity."}
          </DialogDescription>
        </DialogHeader>

        {isCustomField && (
          <div className="space-y-4">
            <div>
              <Label htmlFor="title">Title</Label>
              <Input
                id="title"
                className="mt-1 w-full border border-gray-300 rounded-md shadow-sm sm:text-sm focus:border-0"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter a title for the custom activity"
              />
              {titleError && (
                <p className="text-red-500 text-sm">{titleError}</p>
              )}
            </div>
          </div>
        )}

        <div className="space-y-4">
          <div>
            <Label htmlFor="description">Description</Label>
            <Input
              id="description"
              className="mt-1 w-full border border-gray-300 rounded-md shadow-sm sm:text-sm focus:border-0"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a description"
            />
            {descriptionError && (
              <p className="text-red-500 text-sm">{descriptionError}</p>
            )}
          </div>
        </div>

        <DialogFooter className="flex flex-row justify-end gap-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSaveClick} disabled={loading}>
            {loading ? "Saving..." : "Save"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
