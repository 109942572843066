import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchQrLinkApi, refreshQrLinkApi } from "../services/API";

interface QrState {
  status: "idle" | "loading" | "succeeded" | "failed";
  qrLink: string | null;
  error: string | null;
}

const initialState: QrState = {
  status: "idle",
  qrLink: null,
  error: null,
};

// thunk to fetch the QR link
export const fetchQrLink = createAsyncThunk<string, string, { rejectValue: string }>(
  "qr/fetchQrLink",
  async (token: string, { rejectWithValue }) => {
    try {
      const qrLink = await fetchQrLinkApi(token);
      return qrLink;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || "Failed to fetch QR link");
    }
  }
);

// thunk to refresh the QR link
export const refreshQrLink = createAsyncThunk<string, string, { rejectValue: string }>(
    "qr/refreshQrLink",
    async (token: string, { rejectWithValue }) => {
      try {
        const qrLink = await refreshQrLinkApi(token); 
        return qrLink;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || "Failed to refresh QR link");
      }
    }
  );

const qrSlice = createSlice({
  name: "qr",
  initialState,
  reducers: {
    resetQrState(state) {
      state.status = "idle";
      state.qrLink = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQrLink.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchQrLink.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = "succeeded";
        state.qrLink = action.payload;
      })
      .addCase(fetchQrLink.rejected, (state, action: PayloadAction<any>) => {
        state.status = "failed";
        state.error = action.payload || "An error occurred while fetching QR link";
      })
      .addCase(refreshQrLink.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(refreshQrLink.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = "succeeded";
        state.qrLink = action.payload;
      })
      .addCase(refreshQrLink.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.status = "failed";
        state.error = action.payload || "An error occurred while refreshing QR link";
      });
  },
});

export const { resetQrState } = qrSlice.actions;
export default qrSlice.reducer;
