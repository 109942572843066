import React, { useEffect } from "react"

const FacebookLoginButton = () => {
  useEffect(() => {
    // Load Facebook SDK
    ;(window as any).fbAsyncInit = function () {
      ;(window as any).FB.init({
        appId: "448188741110400", // Replace with your actual Facebook App ID
        autoLogAppEvents: true,
        xfbml: true,
        version: "v21.0",
      })
    }
    // Listen for messages from the Embedded Signup flow
    window.addEventListener("message", (event) => {
      if (
        event.origin !== "https://www.facebook.com" &&
        event.origin !== "https://web.facebook.com"
      ) {
        return
      }
      try {
        const data = JSON.parse(event.data)
        if (data.type === "WA_EMBEDDED_SIGNUP") {
          handleEmbeddedSignupEvents(data)
        }
        console.log("session-info-response: ", JSON.stringify(data, null, 2))
      } catch {
        console.log("Non JSON Responses", event.data)
      }
    })
  }, [])

  // Handler for Embedded Signup events
  const handleEmbeddedSignupEvents = (data: any) => {
    switch (data.event) {
      case "FINISH":
        const { phone_number_id, waba_id } = data.data
        console.log(
          "Phone number ID:",
          phone_number_id,
          "WhatsApp business account ID:",
          waba_id
        )
        break
      case "CANCEL":
        console.warn("Signup canceled at step:", data.data.current_step)
        break
      case "ERROR":
        console.error("Error during signup:", data.data.error_message)
        break
      default:
        break
    }
  }

  // Callback after successful login
  const fbLoginCallback = (response: any) => {
    if (response.authResponse) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const code = response.authResponse.code
      // Send `code` to your backend for server-to-server token exchange
    }
    console.log("sdk-response: ", JSON.stringify(response, null, 2))
  }

  // Function to launch WhatsApp Embedded Signup
  const launchWhatsAppSignup = () => {
    ;(window as any).FB.login(fbLoginCallback, {
      config_id: "1278916226487023", // Replace with your actual configuration ID
      response_type: "code", // Must be 'code' for server-to-server access
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: "",
        sessionInfoVersion: "2",
      },
    })
  }

  return (
    <div>
      <button
        onClick={launchWhatsAppSignup}
        style={{
          backgroundColor: "#1877f2",
          border: 0,
          borderRadius: "4px",
          color: "#fff",
          cursor: "pointer",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontSize: "16px",
          fontWeight: "bold",
          height: "40px",
          padding: "0 24px",
        }}>
        Login with Facebook
      </button>
      <p>Session info response:</p>
      <pre id="session-info-response"></pre>
      <br />
      <p>SDK response:</p>
      <pre id="sdk-response"></pre>
    </div>
  )
}

export default FacebookLoginButton
