import { Card, CardHeader } from "../../../../@/components/ui/card";
import { Skeleton } from "../../../../@/components/ui/skeleton";
import React from "react";

export default function SelectedChatLoadingComponent(){
    return(
        <>
        <Card className="border-0 p-1">
          <CardHeader className="flex flex-row items-center p-2">
            <div className="flex items-center space-x-2 ml-3">
              <Skeleton className="h-12 w-12 rounded-full" />
              <div>
                <Skeleton className="h-4 w-[150px]" />
              </div>
            </div>
          </CardHeader>
        </Card>
        {Array.from({ length: 4 }).map((_, index) => (
          <div
            key={index}
            className={`flex ${
              index % 2 === 0 ? "justify-start pl-3" : "justify-end pr-3"
            } space-y-2 my-2`}
          >
            <div className={`max-w-[75%]`}>
              <Skeleton className="h-[15vh] w-[25vw] rounded-lg" />
            </div>
          </div>
        ))}
        </>
    );
}