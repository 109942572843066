import React from "react";
import NavBar from "../navigationBar/NavigationBar";
import LeadDetails from "./LeadDetails";

export default function LeadSection() {
  return (
    <>
      <NavBar />
      <div className="h-[90vh] flex flex-row">
        <LeadDetails/>
      </div>
    </>
  );
}
