import React from "react";
import MyCard from "./chatListComponent/chatListBody/Mycard";
import ChatListHeader from "./chatListComponent/ChatListHeader";

interface ChatListProps {
  onChatSelect: () => void;
}

export default function ChatList({ onChatSelect }: ChatListProps) {
  return (
    <>
      <ChatListHeader />
      <MyCard onChatSelect={onChatSelect} />
    </>
  );
}
