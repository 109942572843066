import React from "react";

export default function ProductExcel(){
    return(
        <div className="w-full h-full">
        <iframe
        className="airtable-embed"
        src="https://docs.google.com/spreadsheets/d/13mV9D4szQRBXV1ZerR52XDLf1pPXa9fXkxz-W3Js1lQ/edit?usp=drivesdk?gid=29330734&amp;single=true&amp;widget=true&amp;headers=false&amp;chrome=false"
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ background: "transparent", border: "1px solid #ccc" }}
        title="Product Excel"
      ></iframe>
        </div>
    );
}