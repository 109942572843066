import React from "react";
import CustomerSection from "../pageComponents/customers/CustomerSection";

const CustomerPage: React.FC = () => {
    return (
        <div className="mt-[10vh]">
          <CustomerSection/>
        </div>
      );
}
export default CustomerPage;