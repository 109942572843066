import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authSlice';
import chatReducer from './chatSlice';
import messageReducer from './messageSlice';
import activityReducer from './ActivityLogSlice'
import selectedUserReducer from './SelectedChatUserSlice';
import leadReducer from './leadTemplate';
import selectedUserLeadTemplateReducer from './SelectedUserLeadTemplateSlice';
import adminReducer from './adminSlice';
import aiReplyReducer from './AIReplySlice';
import selectedOrderReducer from './SelectedUserOrdersSlice';
import orderReducer from './OrdersSlice';
import qrReducer from './qrSlice';
import botStatusReducer from './BotReplyStatus'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], 
};

const rootReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  message: messageReducer,
  activityLog: activityReducer,
  selectedUser: selectedUserReducer,
  lead: leadReducer,
  selectedUserLeadTemplate: selectedUserLeadTemplateReducer,
  admin: adminReducer,
  aiReply: aiReplyReducer,
  orders: selectedOrderReducer,
  ordersList: orderReducer,
  qr:qrReducer,
  botStatus: botStatusReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;



