import { Button } from "../../../../../@/components/ui/button";
import React, { useState } from "react";
import { HiDatabase } from "react-icons/hi";
import AddNewLeadDataCard from "./AddNewLeadDataCard";

export default function NoLeadMessage(){
    const [showCard, setShowCard] = useState(false);
    // const business = useSelector((state: RootState) => state.auth.business);
    // const userId = useSelector((state: RootState) => state.selectedUser.userId);

    // console.log("Business in:", business); // Check if it logs the value
    // console.log("UserId in :", userId);

    const handleAddTemplateData = () => {
        setShowCard(true); 
      };
    
      const handleCancel = () => {
        setShowCard(false); 
      };
    return(
        <>
         {!showCard ? (
        <div className="flex flex-col items-center justify-center h-full text-center bg-white">
            <div className="mb-4 flex flex-col items-center">
              <HiDatabase className="text-gray-300" size={50} />
              <h3 className="text-sm font-semibold text-gray-500 mt-4">
                No lead data available.
              </h3>
              <Button onClick={handleAddTemplateData} className="mt-4 text-sm ">
                Save as Lead
              </Button>
            </div>
          </div>
          ) : (
            <div>
              <AddNewLeadDataCard onCancel={handleCancel}/>
            </div>
          )}
          </>
    );
}