import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../@/components/ui/button";
import React, { useEffect} from "react";
import { AppDispatch, RootState } from "store/store";
import { fetchQrLink, refreshQrLink, resetQrState } from "store/qrSlice";

export default function RefreshQR(){
    const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.auth.token);
  const { qrLink, status, error } = useSelector((state: RootState) => state.qr);

  useEffect(() => {
    if (token) {
      dispatch(fetchQrLink(token));
    }

    return () => {
      dispatch(resetQrState());
    };
  }, [dispatch, token]);

    // const refreshQRCode = () => {
    //     setQrCode(`https://via.placeholder.com/150?text=QR${Date.now()}`);
    // };

    const refreshQRCode = () => {
        if (token) {
          dispatch(refreshQrLink(token));
        }
      };

    return(
        <div className="flex items-center justify-center min-h-screen">
      <div className="flex flex-col items-center p-6 space-y-6 border-gray-200 rounded-lg shadow-lg">
        <div className="w-[350px] flex flex-col items-center space-y-4">
          {status === "loading" && <p>Loading QR Code...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {qrLink ? (
            <img
              src={qrLink}
              alt="QR Code"
              width={350}
              height={350}
              className="border border-gray-300 rounded-lg"
            />
          ) : (
            !error && status !== "loading" && <p>QR code not available</p>
          )}
          <Button onClick={refreshQRCode} className="w-full text-white font-semibold py-2 rounded transition duration-200">
            Refresh QR Code
          </Button>
        </div>
      </div>
    </div>
    );
}