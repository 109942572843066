import React from "react";
import NavBar from "../../navigationBar/NavigationBar";
import OrderDashboardLayout from "./orderComponents/OrderLayOut";

export default function OrderSection() {
  return (
    <>
      <NavBar />
      <div className="flex flex-row bg-muted/40 mt-1">
        <OrderDashboardLayout/>
      </div>
    </>
  );
}
