import AnalysisPageComponent from "pageComponents/analysis/AnalysisPageComponent";
import React from "react";

export default function AnalysisPage() {
  return (
    <>
      <AnalysisPageComponent />
    </>
  );
}
