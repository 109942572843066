import { CardContent } from "../../../../../@/components/ui/card";
import { Skeleton } from "../../../../../@/components/ui/skeleton";
import React from "react";

export default function ActivityLoadingComponent(){
    return(
        <CardContent className="p-3 space-y-4">
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <div
                key={index}
                className="border rounded-md pb-2 pt-2 shadow-sm bg-white flex flex-col space-y-2"
              >
                <div className="flex justify-between items-center">
                  <Skeleton className="h-3 w-[20vw]" />
                </div>
                <Skeleton className="h-2 w-[18vw]" />
                <Skeleton className="h-2 w-[15vw]" />
              </div>
            ))}
        </CardContent>
    );
}