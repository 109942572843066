import { AlignRight } from "lucide-react";
import { Button } from "../../../../../@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../@/components/ui/dialog";
import React, { useState } from "react";
import { ScrollArea } from "../../../../../@/components/ui/scroll-area";
import { Checkbox } from "../../../../../@/components/ui/checkbox";
import { Label } from "../../../../../@/components/ui/label";

interface MobileFilterProps {
  selectedAdminId: string;
  selectedTemplate: string;
  selectedStatuses: { templateName: string; status: string }[];
  admins: any;
  leadTemplates: any;
  handleTemplateSelection: (templateName: string, templateId: string) => void;
  handleStatusChange: (status: string) => void;
  handleOwnerSelection: (leadOwnerId: string) => void;
}

export default function MobileFilterComponent({
  selectedAdminId,
  selectedTemplate,
  selectedStatuses,
  admins,
  leadTemplates,
  handleTemplateSelection,
  handleStatusChange,
  handleOwnerSelection,
}: MobileFilterProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopoverOpen = () => setPopoverOpen((prevOpen) => !prevOpen);

  return (
    <>
      <Dialog open={popoverOpen} onOpenChange={setPopoverOpen}>
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            className="p-2 hover:bg-gray-100 transition-all"
            onClick={togglePopoverOpen}
          >
            <AlignRight className="h-5 w-5 text-gray-700" />
          </Button>
        </DialogTrigger>

        <DialogContent className="w-[80%] h-[65%]  bg-white p-4">
          <DialogHeader>
            <DialogTitle className="text-sm">Filter By</DialogTitle>
          </DialogHeader>
          <ScrollArea className=" ">
            <div className="space-y-4">
              <div className="px-2">
                <h3 className="text-sm font-medium pb-1">Contact Owner</h3>
                {admins.length === 0 ? (
                  <div className="text-xs text-gray-500">
                    No contact owners available.
                  </div>
                ) : (
                  admins.map((admin: any) => (
                    <div key={admin._id} className="flex items-center">
                      <Checkbox
                        checked={selectedAdminId === admin._id}
                        onCheckedChange={() => handleOwnerSelection(admin._id)}
                        id={admin.name}
                        className="mr-2"
                      />
                      <Label
                        htmlFor={admin.name}
                        className="text-sm font-normal text-gray-700 capitalize"
                      >
                        {admin.name}
                      </Label>
                    </div>
                  ))
                )}
              </div>

              <div className="px-2">
                <h3 className="text-sm font-medium">Lead Status</h3>
                {leadTemplates.length === 0 ? (
                  <div className="text-xs text-gray-500">
                    No lead templates available.
                  </div>
                ) : (
                  leadTemplates.map((template: any) => (
                    <div key={template.lead_template_id}>
                      <Checkbox
                        checked={
                          selectedTemplate === template.lead_template_name
                        }
                        onCheckedChange={() =>
                          handleTemplateSelection(
                            template.lead_template_name,
                            template.lead_template_id
                          )
                        }
                        id={template.lead_template_name}
                        className="mr-2"
                      />
                      <Label
                        htmlFor={template.lead_template_name}
                        className="text-sm font-medium text-gray-700 capitalize"
                      >
                        {template.lead_template_name}
                      </Label>
                      <div className="ml-6">
                        {template.lead_template_name.toLowerCase() ===
                        "Whatsapp Orders".toLowerCase()
                          ? [
                              "Partial Order",
                              "New Order",
                              "Confirmed",
                              "Cancelled",
                              "Dispatched",
                            ].map((status) => (
                              <div key={status} className="flex items-center">
                                <Checkbox
                                  checked={selectedStatuses.some(
                                    (s) =>
                                      s.templateName ===
                                        template.lead_template_name &&
                                      s.status === status
                                  )}
                                  onCheckedChange={() =>
                                    handleStatusChange(status)
                                  }
                                  id={status}
                                  className="mr-2"
                                  disabled={
                                    selectedTemplate !==
                                    template.lead_template_name
                                  }
                                />
                                <Label
                                  htmlFor={status}
                                  className="text-sm capitalize font-normal"
                                >
                                  {status}
                                </Label>
                              </div>
                            ))
                          : template.lead_template_status_list.map(
                              (status: string) => (
                                <div key={status} className="flex items-center">
                                  <Checkbox
                                    checked={selectedStatuses.some(
                                      (s) =>
                                        s.templateName ===
                                          template.lead_template_name &&
                                        s.status === status
                                    )}
                                    onCheckedChange={() =>
                                      handleStatusChange(status)
                                    }
                                    id={status}
                                    className="mr-2"
                                    disabled={
                                      selectedTemplate !==
                                      template.lead_template_name
                                    }
                                  />
                                  <Label
                                    htmlFor={status}
                                    className="text-sm capitalize font-normal"
                                  >
                                    {status}
                                  </Label>
                                </div>
                              )
                            )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </ScrollArea>
        </DialogContent>
      </Dialog>
    </>
  );
}
