import React, { useEffect } from "react"
import "./styles/global.css"
import MyCard from "./pages/ChatPage"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom"
// import LoginForm from './pages/LoginPage';
// import SignupForm from './pages/SignupPage';
import OrderPage from "pages/OrdersPage"
import ProductPage from "pages/ProductsPage"
import { Provider, useDispatch, useSelector } from "react-redux"
import store, { persistor, RootState } from "./store/store"
import LoginForm from "pageComponents/login/Login"
import CustomerPage from "pages/CustomerPage"
import LeadPage from "pages/LeadPage"
import AnalysisPage from "pages/AnalysisPage"

import { Toaster } from "./@/components/ui/toaster"
import { PersistGate } from "redux-persist/integration/react"
import PrivateRoute from "PrivateRoute"
import { logout } from "store/authSlice"
import OneSignal from "react-onesignal"
import NavigationSettingComponent from "pageComponents/navigationBar/NavigationDropDownComponents/NavigationSettingComponent"
import QRPage from "pages/QRPage"
import TestPage from "pages/testPage"

const TokenCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const token = useSelector((state: RootState) => state.auth.token)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const checkTokenValidity = () => {
      if (token) {
        try {
          const decodedToken = JSON.parse(atob(token.split(".")[1]))
          const expirationTime = decodedToken.exp * 1000
          if (Date.now() >= expirationTime) {
            dispatch(logout())
            navigate("/", { replace: true })
          }
        } catch (error) {
          console.error("Error checking token validity:", error)
          dispatch(logout())
          navigate("/", { replace: true })
        }
      }
    }

    checkTokenValidity()
    const interval = setInterval(checkTokenValidity, 60000) // Check every minute

    return () => clearInterval(interval)
  }, [token, dispatch, navigate])

  return <>{children}</>
}

function App() {
  // useEffect(() => {
  //   const initializeOneSignal = async () => {
  //     try {
  //       await OneSignal.init({
  //         appId: '87ef3767-1658-4898-aef6-8d123fa45eac',
  //         serviceWorkerPath: './OneSignalSDKWorker.js', // Path to the service worker (for web)
  //         notifyButton: {
  //           enable: true, // Optional: Enable the OneSignal notification bell
  //         },
  //       });

  //       // Ask the user for push notification permission
  //       (OneSignal as any).showSlidedownPrompt();

  //       // Optional: Listen for notification events
  //       (OneSignal as any).on('notificationDisplay', (event: any) => {
  //         console.log('OneSignal notification displayed:', event);
  //       });
  //     } catch (error) {
  //       console.error('OneSignal initialization failed:', error);
  //     }
  //   };

  //   initializeOneSignal();
  // }, []);

  useEffect(() => {
    const initializeOneSignal = async () => {
      try {
        await OneSignal.init({
          appId: "87ef3767-1658-4898-aef6-8d123fa45eac",
          serviceWorkerPath: "./OneSignalSDKWorker.js",
          notifyButton: {
            enable: true,
          },
        })
        ;(OneSignal as any).showSlidedownPrompt()
        ;(OneSignal as any).on("notificationDisplay", (event: any) => {
          console.log("Notification received from backend:", event)
        })

        // Handle notification click event
        // (OneSignal as any).on('notificationClick', (event: any) => {
        //   console.log('Notification clicked:', event);
        //   if (event.data && event.data.leadId) {
        //     navigate(`/lead-details/${event.data.leadId}`);
        //   }
        // });
      } catch (error) {
        console.error("OneSignal initialization failed:", error)
      }
    }

    initializeOneSignal()
  }, [])

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}>
        <Router>
          <TokenCheck>
            <div>
              <Routes>
                <Route
                  path="/"
                  element={<LoginForm />}
                />
                {/* <Route path="/signup" element={<SignupForm/>} /> */}
                <Route
                  path="/privacy-policy"
                  element={<NavigationSettingComponent />}
                />
                <Route
                  path="/chatSection"
                  element={<PrivateRoute element={<MyCard />} />}
                />
                <Route
                  path="/orderSection"
                  element={<PrivateRoute element={<OrderPage />} />}
                />
                <Route
                  path="/productSection"
                  element={<PrivateRoute element={<ProductPage />} />}
                />
                <Route
                  path="/customerSection"
                  element={<PrivateRoute element={<CustomerPage />} />}
                />
                <Route
                  path="/leadSection"
                  element={<PrivateRoute element={<LeadPage />} />}
                />
                <Route
                  path="/qrSection"
                  element={<PrivateRoute element={<QRPage />} />}
                />
                <Route
                  path="/test"
                  element={<TestPage />}
                />
                <Route
                  path="/analysis"
                  element={<AnalysisPage/>}
                ></Route>
                {/* <Route path="/" element={<LoginForm/>} /> */}
              </Routes>
              <Toaster />
            </div>
          </TokenCheck>
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
