import React, { useState } from "react";
// import MyCard from "./Mycard";
import DetailsSection from "./detailsTabs/DetailsSection";
import { CardsChat } from "./selectedChat/SelectedChat";
import ChatList from "./chatList/ChatList";
import NavBar from "../navigationBar/NavigationBar";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { useMedia } from "react-use";

export default function ChatSection() {
  const { selectedChatroomId } = useSelector(
    (state: RootState) => state.message
  );
  const [showDetails, setShowDetails] = useState(false);
  const [showChatList, setShowChatList] = useState(true);
  const isMobile = useMedia('(max-width: 640px)');

  const handleChatSelect = () => {
    if (isMobile) {
      setShowDetails(false);
      setShowChatList(false);
    }
  };

  const handleBackClick = () => {
    if (isMobile) {
      if (showDetails) {
        setShowDetails(false);
      } else {
        setShowChatList(true);
      }
    }
  };

  const handleShowDetails = () => {
    if (isMobile) {
      setShowDetails(true);
    }
  };

  const shouldShowNavBar = !isMobile || (isMobile && showChatList);

  const getContainerClassName = () => {
    let baseClass = "flex flex-row";
    if (shouldShowNavBar) {
      baseClass += " h-[90vh] mt-[10vh]";
    } else {
      baseClass += " h-screen mt-0";
    }
    return baseClass;
  };

  return (
    <>
    {shouldShowNavBar && <NavBar />}
    <div className={getContainerClassName()}>
      {(!isMobile || (isMobile && showChatList)) && (
        <div className="w-full sm:w-[41vw] md:w-[35vw] lg:w-[28vw] xl:w-[25vw] 2xl:w-[25vw] border-r border-gray-300">
          <ChatList onChatSelect={handleChatSelect} />
        </div>
      )}
      {selectedChatroomId && (!isMobile || (isMobile && !showChatList && !showDetails)) && (
        <div className="w-full sm:w-[43vw] md:w-[42vw] lg:w-[49vw] xl:w-[50vw] 2xl:w-[50vw] border-r border-gray-300">
          <CardsChat onBackClick={handleBackClick} onShowDetails={handleShowDetails} />
        </div>
      )}
      {selectedChatroomId && (!isMobile || (isMobile && showDetails)) && (
        <div className="w-full sm:w-[23vw] md:w-[23vw] lg:w-[23vw] xl:w-[25vw] 2xl:w-[25vw]">
          <DetailsSection onBackClick={handleBackClick} />
        </div>
      )}
    </div>
  </>
  );
}
