import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchChatListApi, ChatItem, ChatListResponse } from '../services/API';
import { RootState } from './store';

interface ChatState {
  chats: ChatItem[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  totalPages: number;
  currentPage: number;
  searchTerm: string;
  isECommerce: boolean | null;
}

const initialState: ChatState = {
  chats: [],
  status: 'idle',
  error: null,
  totalPages: 0,
  currentPage: 1,
  searchTerm: '',
  isECommerce: null,
};

interface FetchChatListArgs {
  label: string;
  limit: number;
  page: number;
  templateId: string;
  leadOwnerId: string;
}

export const fetchChatList = createAsyncThunk<
ChatListResponse, 
FetchChatListArgs, 
{ rejectValue: string; state: RootState }  
>(
  'chat/fetchChatList',
  async (
    { label, limit, page, templateId, leadOwnerId }: FetchChatListArgs,
    { rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const token = state.auth.token;
    //const token = localStorage.getItem("token"); 

    console.log("Fetching chat list withdd:", {
      label,
      limit,
      page,
      templateId,
      token, 
    });

    if (!token) {
      return rejectWithValue('Authentication token is missing');
    }

    try {
      const data = await fetchChatListApi(label, limit, page, templateId, leadOwnerId, token); 
      console.log("Chat", data);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);


const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
    resetChats(state) {
      state.chats = [];
      state.currentPage = 1;
      state.totalPages = 0;
      state.status = 'idle'; 
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatList.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchChatList.fulfilled, (state, action: PayloadAction<ChatListResponse>) => {
        state.status = 'succeeded';
        if (action.payload.currentPage === 1) {
          state.chats = action.payload.chats;
        } else {
          const existingChatIds = new Set(state.chats.map(chat => chat.chatroomId));
          const newChats = action.payload.chats.filter(
            chat => !existingChatIds.has(chat.chatroomId)
          );
          state.chats = [...state.chats, ...newChats];
        } 
        state.totalPages = action.payload.totalPages;
        state.currentPage = action.payload.currentPage;
        state.isECommerce = action.payload.isECommerce;
      })
      .addCase(fetchChatList.rejected, (state, action: PayloadAction<any>) => {
        state.status = 'failed';
        state.error = action.payload || 'An error occurred';
        console.error("Chat fetch failed:", action.payload); 
      });
      
  },
});

export const { setSearchTerm, resetChats } = chatSlice.actions;
export default chatSlice.reducer;
