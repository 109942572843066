import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { fetchLeadList, setSelectedTemplate } from "store/leadTemplate";
import { useMedia } from "react-use";
import { fetchAdminList, setFitlterSelectedAdminId } from "store/adminSlice";

import ChatSearchBar from "./chatListHeader/ChatSecrchBar";
import MobileFilterComponent from "./chatListHeader/MobileFilterComponent";
import DesktopFilterComponent from "./chatListHeader/DesktopFilterComponent";

export default function ChatListHeader() {
  const dispatch: AppDispatch = useDispatch();
  const [leadTemplates, setLeadTemplates] = useState<any[]>([]);
  const [selectedTemplate, setLocalSelectedTemplate] = useState<string | null>(
    null
  );
  const [selectedAdminId, setSelectedAdminId] = useState<string | null>(null);
  const [selectedStatuses, setSelectedStatuses] = useState<{
    templateName: string;
    status: string;
  }[]>([]);
  const business = useSelector((state: RootState) => state.auth.business);
  const admins = useSelector((state: RootState) => state.admin.admins);
  const isMobile = useMedia("(max-width: 640px)");

  useEffect(() => {
    if (business) {
      dispatch(fetchLeadList({ business })).then((response: any) => {
        setLeadTemplates(response.payload);
      });
    }
  }, [dispatch, business]);

  useEffect(() => {
    if (business) {
      dispatch(fetchAdminList({ business }));
    }
  }, [business, dispatch]);

  

  const handleTemplateSelection = (
    templateName: string,
  ) => {
    if (selectedTemplate === templateName) {
      setLocalSelectedTemplate(null);
      setSelectedStatuses([]);

      dispatch(
        setSelectedTemplate({
          templateId: "all",
          statuses: [],
        })
      );
    } else {
      setLocalSelectedTemplate(templateName);
      setSelectedStatuses([]);

      const selectedTemplateObj = leadTemplates.find(
        (template) => template.lead_template_name === templateName
      );

      if (selectedTemplateObj) {
        dispatch(
          setSelectedTemplate({
            templateId: selectedTemplateObj.lead_template_id,
            statuses: [],
          })
        );
      }
    }
  };

  const handleStatusChange = (status: string) => {
    const newSelectedStatuses = selectedTemplate 
      ? [{ templateName: selectedTemplate, status }]
      : [];
  
    setSelectedStatuses(newSelectedStatuses);
  
    const isWhatsappOrdersTemplate = selectedTemplate === "Whatsapp Orders";
  
    if (isWhatsappOrdersTemplate) {
      const statusMap: Record<string, string> = {
        "Partial Order": "created",
        "New Order": "confirm",
        Confirmed: "confirmed",
        Cancelled: "cancelled",
        Dispatched: "dispatched",
      };
  
      const mappedStatuses = newSelectedStatuses.map((s) => statusMap[s.status] || s.status);
  
      dispatch(
        setSelectedTemplate({
          templateId: "all",
          statuses: mappedStatuses,
        })
      );
    } else {
      const selectedTemplateObj = leadTemplates.find(
        (template) => template.lead_template_name === selectedTemplate
      );
  
      if (selectedTemplateObj) {
        dispatch(
          setSelectedTemplate({
            templateId: selectedTemplateObj.lead_template_id,
            statuses: newSelectedStatuses.map(s => s.status),
          })
        );
      }
    }
  };

  const handleOwnerSelection = (leadOwnerId: string) => {
    if (selectedAdminId === leadOwnerId) {
      setSelectedAdminId(null);
      dispatch(setFitlterSelectedAdminId("all"));
    } else {
      setSelectedAdminId(leadOwnerId);
      dispatch(setFitlterSelectedAdminId(leadOwnerId));
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row ml-3 items-center justify-between">
        <ChatSearchBar />
        {isMobile ? (
          <MobileFilterComponent
            selectedAdminId={selectedAdminId ?? ""}
            selectedTemplate={selectedTemplate ?? ""}
            selectedStatuses={selectedStatuses}
            admins={admins}
            leadTemplates={leadTemplates}
            handleTemplateSelection={handleTemplateSelection}
            handleStatusChange={handleStatusChange}
            handleOwnerSelection={handleOwnerSelection}
          />
        ) : (
          <DesktopFilterComponent
          selectedAdminId={selectedAdminId ?? ""}
            selectedTemplate={selectedTemplate ?? ""}
            selectedStatuses={selectedStatuses}
            admins={admins}
            leadTemplates={leadTemplates}
            handleTemplateSelection={handleTemplateSelection}
            handleStatusChange={handleStatusChange}
            handleOwnerSelection={handleOwnerSelection}
          />
        )}
      </div>
    </div>
  );
}
