import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postActivityLogApi, fetchLogActivitiesApi, ActivityLogResponse, ActivityLogItem } from "../services/API";

// Thunk for posting activity log
export const postActivityLog = createAsyncThunk<
  ActivityLogResponse,
  { title: string; description: string; user: string }
>("activityLog/postActivityLog", async ({ title, description, user }) => {
  const response = await postActivityLogApi(title, description, user);
  return response;
});

// Thunk for fetching activity logs
export const fetchLogActivities = createAsyncThunk<
  ActivityLogItem[],
  string 
>("activityLog/fetchLogActivities", async (userId) => {
  const response = await fetchLogActivitiesApi(userId);
  return response;
});

interface ActivityLogState {
  loading: boolean;
  success: boolean;
  error: string | null;
  activities: ActivityLogItem[]; 
}

const initialState: ActivityLogState = {
  loading: false,
  success: false,
  error: null,
  activities: [], 
};

const activityLogSlice = createSlice({
  name: "activityLog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //post activity log
      .addCase(postActivityLog.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(postActivityLog.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(postActivityLog.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = action.error.message || "Something went wrong";
      })
      
      // get activity logs
      .addCase(fetchLogActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchLogActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.activities = action.payload;
      })
      .addCase(fetchLogActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      });
  },
});

export default activityLogSlice.reducer;
